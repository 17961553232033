// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // API_BASE_URL: 'http://ec2-44-202-190-99.compute-1.amazonaws.com:8090',
  API_BASE_URL: 'https://app-dev-service.gofriday.ai',
  BUCKET_URL: 'https://admin-dev-assets.gofriday.ai/',
  SLOT_DIFFERENCE :15
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
